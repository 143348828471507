import React from "react";
import Layout from "../components/Layout/Layout";
import {graphql} from "gatsby";
import HeroBlock from "../components/Core/HeroBlock";
import Seo from "../components/Core/Seo";
import {renderRichText} from "gatsby-source-contentful/rich-text"
import IntroBlock from "../components/Core/IntroBlock";
import ArticleContent from "../components/Core/ArticleContent/ArticleContent";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 680px;
  margin: auto;
  padding: 0 40px 100px 40px;

  @media (min-width: ${breakpoints.lg}) {
    padding: 40px 40px 100px 40px;
  }
`;

const Article = ({location, data}) => {
  const {article} = data
  const {title, metaTitle, metaDescription, hero, intro} = article

  let sharingImage = false;

  if (hero && hero.file) {
    sharingImage = hero?.file?.url;
  }

  return (
    <Layout>
      <Seo title={metaTitle}
           description={metaDescription}
           image={sharingImage}
           path={location.pathname}/>
      <HeroBlock title={title} image={(hero && hero.file) ? hero.file.url : null} />
      <StyledContentContainer>
        {intro &&
        <IntroBlock>
          {renderRichText(intro)}
        </IntroBlock>}
        <ArticleContent page={article} />
      </StyledContentContainer>
    </Layout>
  )
}

export default Article;

export const pageQuery = graphql`
  query ArticleQuery($slug: String!) {
    article: contentfulArticle(slug: {eq: $slug}) {
      id
      title
      metaTitle
      metaDescription
      hero {
        file {
          url
        }
      }
      intro {
        raw
      }
      references {
        __typename
        ... on Node {
          ... on ContentfulContentBlock {
            content {
              raw
            }
          }
          ... on ContentfulImageBlock {
            image {
              gatsbyImageData(width: 1200)
              file {
                url
              }
            }
            includeFrame
          }
        }
      }
    }
  }
`
